import { ToastrService } from 'ngx-toastr';

export class YourComponent {
  constructor(private toastr: ToastrService) {}

  showSuccess(message: string) {
    this.toastr.success(message);
  }
}

export { ToastrService };
